<template>
  <div class="view-home">
      <div class="seminars-block seminars-page">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
              <li class="breadcrumbs-item"><router-link to="/events">Мероприятия</router-link></li>
              <li class="breadcrumbs-item">Семинары, тренинги и повышение квалификации</li>
            </ul>
          </nav>
          <div class="seminars-title">
            Семинары, тренинги и повышение квалификации
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
          <div class="seminars-text">
            Повышайте свою квалификацию с нашими семинарами и тренингами! Получите актуальные знания и практические
            навыки от лучших экспертов. Обменяйтесь опытом с коллегами и создайте полезные связи для успеха в вашей
            карьере. Независимо от вашего опыта — у нас есть программы, чтобы помочь вам стать лучше в своей области.
          </div>
          <div class="forums-filters">
            <div class="filter-date date-icon">
              <el-select v-model="dateSeminar" placeholder="Дата проведения" class="filter-date-select" @change="handleDate">
                <el-option
                    v-for="item in datesList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.date_from">
                </el-option>
              </el-select>
            </div>
            <div class="filter-period">
              <el-select v-model="typeSeminar" placeholder="Тип" class="filter-date-select" @change="handleType">
                <el-option
                    v-for="item in typesList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.type">
                </el-option>
              </el-select>
            </div>
            <div class="filter-place">
              <el-select v-model="placeSeminar" placeholder="Место проведения" class="filter-date-select" @change="handlePlace">
                <el-option
                    v-for="item in placesList"
                    :key="item.id"
                    :label="item.place"
                    :value="item.place">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    <div class="seminars-img-back">
      <div class="container">
        <div class="seminars-list-block">
          <div v-if="paginatedItemsSeminar.length" class="seminars-section">
            <div class="seminars-list-title" style="color: #32436D">
              Республиканские семинары
            </div>
            <div class="seminars-list">
              <router-link :to="typeEvent(item.type, item.id)" class="seminars-list-item"
                           v-for="item in paginatedItemsSeminar">
                <div class="seminars-list-item-img">
                  <img :src="getImagePath(item.img)" width="100%" height="100%" alt=""/>
                </div>
                <div class="seminars-list-item-details">
                  <div class="seminars-list-item-date">
              <span>
                Дата проведения: {{ formattedDate(item.start_date, item.end_date) }}
              </span>
                    <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="1" height="24" fill="#D9D9D9"/>
                    </svg>
                    <span>
                Количество часов: 12 часов
              </span>
                  </div>
                  <div class="seminars-list-item-title">
                    {{ item[`title_${$i18n.locale}`] || item.title_ru }}
                  </div>
                  <div class="seminars-list-item-text">
                    {{ item[`text_${$i18n.locale}`] || item.text_ru }}
                  </div>
                  <div class="seminars-list-item-address">
                    Место проведения: {{ item[`location_${$i18n.locale}`] || item.location_ru}}
                  </div>
                  <div class="seminars-list-item-link" @click="openModal($event, item)" v-if="isBeforeSeminarDate(item.date_from)">
                    Зарегистрироваться на {{ $t(`events.${item.type}`) }}
                  </div>
                </div>
              </router-link>
            </div>

            <nav class="seminars-pagination" v-if="totalPagesSeminar > 1">
              <ul class="pagination">
                <li class="page-item">
              <span class="page-link" @click="prevPageSeminar">
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                      fill="black"/>
                </svg>
              </span>
                </li>
                <li class="page-item" v-for="page in totalPagesSeminar"><span class="page-link"
                                                                              :class="{ 'active' : currentPageSeminar == page}"
                                                                              @click="currentPageSeminar=page">{{ page }}</span>
                </li>
                <li class="page-item">
              <span class="page-link" @click="nextPageSeminar">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                      fill="black"/>
                </svg>
              </span
              ></li>
              </ul>
            </nav>
          </div>

          <div v-if="paginatedItemsTraining.length" class="seminars-section">
            <div class="seminars-list-title" style="color: #32436D">
              Тренинги
            </div>
            <div class="seminars-list">
              <router-link :to="typeEvent(item.type, item.id)" class="seminars-list-item"
                           v-for="item in paginatedItemsTraining">
                <div class="seminars-list-item-img">
                  <img :src="getImagePath(item.img)" width="100%" height="100%" alt=""/>
                </div>
                <div class="seminars-list-item-details">
                  <div class="seminars-list-item-date">
              <span>
                Дата проведения: {{ formattedDate(item.start_date, item.end_date) }}
              </span>
                    <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="1" height="24" fill="#D9D9D9"/>
                    </svg>
                    <span>
                Количество часов: 12 часов
              </span>
                  </div>
                  <div class="seminars-list-item-title">
                    {{ item[`name_${$i18n.locale}`] || item.name_ru}}
                  </div>
                  <div class="seminars-list-item-text">
                    {{ item[`goal_${$i18n.locale}`] || item.goal_ru }}
                  </div>
                  <div class="seminars-list-item-address">
                    Место проведения: {{ item[`location_${$i18n.locale}`] || item.location_ru}}
                  </div>
                  <div class="seminars-list-item-link" @click="openModal($event, item)" v-if="isBeforeSeminarDate(item.date_from)">
                    Зарегистрироваться на {{ $t(`events.${item.type}`) }}
                  </div>
                </div>
              </router-link>
            </div>
            <nav class="seminars-pagination" v-if="totalPagesTraining > 1">
              <ul class="pagination">
                <li class="page-item">
              <span class="page-link" @click="prevPageTraining">
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                      fill="black"/>
                </svg>
              </span>
                </li>
                <li class="page-item" v-for="page in totalPagesTraining">
                  <span class="page-link"
                        :class="{ 'active' : currentPageTraining == page}"
                        @click="currentPageTraining=page">
                    {{page}}
                  </span>
                </li>
                <li class="page-item">
              <span class="page-link" @click="nextPageTraining">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                      fill="black"/>
                </svg>
              </span
              ></li>
              </ul>
            </nav>
          </div>

          <div v-if="paginatedItemsCourse.length" class="seminars-section">
            <div class="seminars-list-title" style="color: #32436D">
              Курсы повышение квалификации
            </div>
            <div class="seminars-list">
              <router-link :to="typeEvent(item.type, item.id)" class="seminars-list-item"
                           v-for="item in paginatedItemsCourse">
                <div class="seminars-list-item-img">
                  <img :src="getImagePath(item.img)" width="100%" height="100%" alt=""/>
                </div>
                <div class="seminars-list-item-details">
                  <div class="seminars-list-item-date">
              <span>
                Дата проведения: {{ formattedDate(item.start_date, item.end_date) }}
              </span>
                    <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="1" height="24" fill="#D9D9D9"/>
                    </svg>
                    <span>
                Количество часов: 12 часов
              </span>
                  </div>
                  <div class="seminars-list-item-title">
                    {{ item[`title_${$i18n.locale}`] || item.title_ru }}
                  </div>
                  <div class="seminars-list-item-text">
                    {{ item[`description_${$i18n.locale}`] || item.description_ru }}
                  </div>
                  <div class="seminars-list-item-address">
                    Место проведения: {{ item[`location_${$i18n.locale}`] || item.location_ru}}
                  </div>
                  <div class="seminars-list-item-link" @click="openModal($event, item)" v-if="isBeforeSeminarDate(item.date_from)">
                    Зарегистрироваться на {{ $t(`events.${item.type}`) }}
                  </div>
                </div>
              </router-link>
            </div>
            <nav class="seminars-pagination" v-if="totalPagesCourse > 1">
              <ul class="pagination">
                <li class="page-item">
              <span class="page-link" @click="prevPageCourse">
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                      fill="black"/>
                </svg>
              </span>
                </li>
                <li class="page-item" v-for="page in totalPagesCourse"><span class="page-link"
                                                                             :class="{ 'active' : currentPageCourse == page}"
                                                                             @click="currentPageCourse=page">{{ page }}</span>
                </li>
                <li class="page-item">
              <span class="page-link" @click="nextPageCourse">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                      fill="black"/>
                </svg>
              </span
              ></li>
              </ul>
            </nav>
          </div>
        </div>

<!--        <nav class="seminars-pagination">-->
<!--          <ul class="pagination">-->
<!--            <li class="page-item">-->
<!--              <span class="page-link" @click="prevPage">-->
<!--                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                      d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"-->
<!--                      fill="black"/>-->
<!--                </svg>-->
<!--              </span>-->
<!--            </li>-->
<!--            <li class="page-item" v-for="page in totalPages"><span class="page-link" :class="{ 'active' : currentPage == page}" @click="currentPage=page">{{page}}</span></li>-->
<!--            <li class="page-item">-->
<!--              <span class="page-link" @click="nextPage">-->
<!--                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path-->
<!--                      d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"-->
<!--                      fill="black"/>-->
<!--                </svg>-->
<!--              </span-->
<!--              ></li>-->
<!--          </ul>-->
<!--        </nav>-->
      </div>
    </div>

    <el-dialog
        :visible.sync="dialogVisibleSeminars"
        :width="modalWidthSeminars"
    >
      <div class="seminars-modal-head">
        <img src="/images/modal-train-img.png" alt="" width="48" height="48">
        <div>
          <div class="seminars-modal-head-title">
            Регистрация на тренинг
          </div>
          <div class="seminars-modal-head-subtitle">
            Тренинг “Развитие эмоционального интеллекта в воспитании детей”
          </div>
        </div>
      </div>
      <div class="divide-line">
      </div>
      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            ФИО*
          </div>
          <span>
            (на русском)
          </span>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>
      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            ФИО*
          </div>
          <span>
            (на английском из паспорта)
          </span>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>

      <div class="divide-line">
      </div>

      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            Область
          </div>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>
      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            Город/район
          </div>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>

      <div class="divide-line">
      </div>

      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            Наименование организации
          </div>
          <span>
            (место работы)*
          </span>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>
      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            Адрес организации*
          </div>
        </div>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="Please input"
            v-model="textarea1">
        </el-input>
      </div>

      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            Должность*
          </div>
        </div>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="Please input"
            v-model="textarea2">
        </el-input>
      </div>

      <div class="divide-line">
      </div>

      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            Контактный телефон *
          </div>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>
      <div class="seminars-modal-input">
        <div class="seminars-modal-label">
          <div>
            E-mail*
          </div>
        </div>
        <el-input placeholder="Please input" v-model="input"></el-input>
      </div>

      <div class="trainings-modal-btns">
        <button class="trainings-modal-cancel">
          Отменить
        </button>
        <button class="trainings-modal-request">
          Зарегистрироваться
        </button>
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="modalSuccessfullyRegistered"
        :width="modalSuccessfullyRegisteredWidth">

      <div class="modal-success-block">
        <img :src="backUrl + forumInfo.image" width="100%" alt="">
        <div class="modal-success-title">
          Поздравляем! Вы успешно зарегистрировались на мероприятие!
        </div>
        <div class="modal-success-text">
          {{forumInfo.name}}
        </div>
        <div class="modal-success-questions">
          По вопросам обращаться:
        </div>
        <div class="d-flex justify-content-between">
          <div class="modal-success-email">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7725 16.2839H6.08694C3.78126 16.2839 2.24414 15.131 2.24414 12.4411V7.06118C2.24414 4.37122 3.78126 3.21838 6.08694 3.21838H13.7725C16.0782 3.21838 17.6153 4.37122 17.6153 7.06118V12.4411C17.6153 15.131 16.0782 16.2839 13.7725 16.2839Z" stroke="#1090CB" stroke-width="1.53712" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.7735 7.44543L11.3679 9.36683C10.5763 9.99705 9.27741 9.99705 8.48579 9.36683L6.08789 7.44543" stroke="#1090CB" stroke-width="1.53712" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            qabilet@el-umiti.kz
          </div>
          <div class="modal-success-phone">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.84721 17.821C14.3041 17.821 17.9171 14.208 17.9171 9.75114C17.9171 5.29428 14.3041 1.68127 9.84721 1.68127C5.39035 1.68127 1.77734 5.29428 1.77734 9.75114C1.77734 11.1985 2.15836 12.5568 2.82555 13.7313L1.77734 17.821L5.99377 16.8433C7.13884 17.4668 8.45165 17.821 9.84721 17.821ZM9.84721 16.5795C13.6184 16.5795 16.6756 13.5223 16.6756 9.75114C16.6756 5.97995 13.6184 2.92279 9.84721 2.92279C6.07602 2.92279 3.01886 5.97995 3.01886 9.75114C3.01886 11.2072 3.47461 12.5568 4.25124 13.6652L3.63962 15.9587L5.97364 15.3752C7.07427 16.1347 8.4088 16.5795 9.84721 16.5795Z" fill="#1090CB" stroke="#1090CB" stroke-width="0.153712"/>
              <path d="M7.83107 6.00438C7.6392 5.619 7.34487 5.65312 7.04752 5.65312C6.51611 5.65312 5.6875 6.28964 5.6875 7.47429C5.6875 8.44516 6.11532 9.50794 7.55692 11.0978C8.94818 12.632 10.7762 13.4257 12.2938 13.3987C13.8114 13.3717 14.1236 12.0657 14.1237 11.6247C14.1237 11.4292 14.0023 11.3317 13.9188 11.3052C13.4016 11.057 12.4478 10.5946 12.2308 10.5077C12.0137 10.4208 11.9004 10.5383 11.83 10.6022C11.6332 10.7898 11.243 11.3425 11.1094 11.4669C10.9759 11.5912 10.7767 11.5283 10.6938 11.4813C10.3889 11.3589 9.562 10.9911 8.90289 10.3522C8.08775 9.56198 8.03991 9.29011 7.88634 9.04812C7.76348 8.85452 7.85363 8.73574 7.89862 8.68383C8.07425 8.48118 8.31675 8.16832 8.4255 8.01284C8.53426 7.85736 8.44792 7.62131 8.39612 7.47429C8.17332 6.84199 7.98457 6.31268 7.83107 6.00438Z" fill="#1090CB"/>
            </svg>
            8 727 310 02 58
          </div>
        </div>
        <router-link to="/events" class="modal-success-btn">Вернуться к мероприятиям</router-link>
      </div>


    </el-dialog>
  </div>
</template>
<script>

import Vue from "vue";
import VueMask from 'v-mask'

export default {
  beforeCreate() {
    Vue.use(VueMask);
  },
  mounted() {
    if (window.innerWidth < 992) {
      this.modalWidthSeminars = "100%"
    }

    const seminarList = this.$http.get(window.API_TEST + '/api/events/seminar/list');
    const trainingList = this.$http.get(window.API_TEST + '/api/events/training/list');
    const refresherCourseList = this.$http.get(window.API_TEST + '/api/events/refresher-course/list');

    Promise.all([seminarList, trainingList, refresherCourseList])
        .then((responses) => {

          this.seminarList = responses[0].body
          this.seminarList = this.seminarList.map( item => ({...item, type: 'seminar'}))

          this.trainingList = responses[1].body
          this.trainingList = this.trainingList.map( item => ({...item, type: 'training'}))

          this.refresherCourseList = responses[2].body
          this.refresherCourseList = this.refresherCourseList.map( item => ({...item, type: 'refresher_course'}))

          this.allInfo = responses.map(response => response.body).flat();
          this.displayedItems = responses.map(response => response.body).flat();
          this.displayedItemsSeminar = responses[0].body
          this.displayedItemsSeminar = this.displayedItemsSeminar.map( item => ({...item, type: 'seminar'}))

          this.displayedItemsTraining = responses[1].body
          this.displayedItemsTraining = this.displayedItemsTraining.map( item => ({...item, type: 'training'}))

          this.displayedItemsCourse = responses[2].body
          this.displayedItemsCourse = this.displayedItemsCourse.map( item => ({...item, type: 'refresher_course'}))


          console.log('this.displayedItemsSeminar', this.displayedItemsSeminar)

          this.forumInfo = this.allInfo[0]
          this.totalPages = Math.ceil(this.allInfo.length / this.itemsPerPage);

          this.totalPagesSeminar = Math.ceil(this.seminarList.length / this.itemsPerPage);
          this.totalPagesTraining = Math.ceil(this.trainingList.length / this.itemsPerPage);
          this.totalPagesCourse = Math.ceil(this.refresherCourseList.length / this.itemsPerPage);

          this.collectDates()
          this.collectPlaces()

        })
        .catch((error) => {
          console.error('Ошибка', error);
        });

  },
  filters: {

  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.displayedItems.slice(startIndex, endIndex);
    },
    paginatedItemsSeminar() {
      const startIndex = (this.currentPageSeminar - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.displayedItemsSeminar.slice(startIndex, endIndex);
    },
    paginatedItemsTraining() {
      const startIndex = (this.currentPageTraining - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.displayedItemsTraining.slice(startIndex, endIndex);
    },
    paginatedItemsCourse() {
      const startIndex = (this.currentPageCourse - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.displayedItemsCourse.slice(startIndex, endIndex);
    },
  },
  methods: {
    getImagePath(path) {
      if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
      } else {
        return this.backUrl + path;
      }
    },
    isBeforeSeminarDate(date) {
      const forumDate = new Date(date);
      const currentDate = new Date();
      return currentDate < forumDate;
    },
    typeEvent(type, id) {
      if (type == 'refresher_course' || type == 'international_course') {
        return `/one-training/${type}/${id}`
      } else {
        return `/one-seminar/${type}/${id}`
      }
    },
    prevPage() {
      if (this.currentPage == 1) return 1
      this.currentPage--
    },
    prevPageSeminar() {
      if (this.currentPageSeminar == 1) return 1
      this.currentPageSeminar--
    },
    prevPageTraining() {
      if (this.currentPageTraining == 1) return 1
      this.currentPageTraining--
    },
    prevPageCourse() {
      if (this.currentPageCourse == 1) return 1
      this.currentPageCourse--
    },
    nextPage() {
      if (this.currentPage == this.totalPages) return this.totalPages
      this.currentPage++
    },
    nextPageSeminar() {
      if (this.currentPageSeminar == this.totalPagesSeminar) return this.totalPagesSeminar
      this.currentPageSeminar++
    },
    nextPageTraining() {
      if (this.currentPageTraining == this.totalPagesTraining) return this.totalPagesTraining
      this.currentPageTraining++
    },
    nextPageCourse() {
      if (this.currentPageCourse == this.totalPagesCourse) return this.totalPagesCourse
      this.currentPageCourse++
    },
    openModal(event, item) {
      event.stopPropagation()
      event.preventDefault()
      this.forumInfo = item
      this.modalSuccessfullyRegistered = true
    },
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    },
    enforcePhoneFormat() {
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.presentationForm.phone = x[0];
    },
    collectPlaces() {
      const uniqueSet = new Set();

      this.placesList = this.allInfo.reduce((acc, forum) => {
        if (!uniqueSet.has(forum.venue)) {
          uniqueSet.add(forum.venue);
          acc.push({
            id: forum.id,
            place: forum.venue
          });
        }
        return acc;
      }, []);
    },
    collectDates() {
      const uniqueSet = new Set();
      this.datesList = this.allInfo.reduce((acc, item) => {
        const date = new Date(item.date_from);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;

        if (!uniqueSet.has(formattedDate)) {
          uniqueSet.add(formattedDate);
          acc.push({
            id: item.id,
            date_from: item.date_from,
            name: formattedDate
          });
        }
        return acc;
      }, []);

      this.datesList.sort( (a, b) => {

        let dateA = new Date(a.date_from);
        let dateB = new Date(b.date_from);
        return dateA - dateB;
      })

    },
    handleType() {
      this.displayedItems = this.allInfo.filter(item => item.type == this.typeSeminar)

      this.displayedItemsSeminar = this.seminarList.filter(item => item.type == this.typeSeminar)
      this.displayedItemsTraining = this.trainingList.filter(item => item.type == this.typeSeminar)
      this.displayedItemsCourse = this.refresherCourseList.filter(item => item.type == this.typeSeminar)

      this.dateSeminar = ''
      this.placeSeminar = ''
      this.totalPages = Math.ceil(this.displayedItems.length / this.itemsPerPage);

      this.totalPagesSeminar = Math.ceil(this.displayedItemsSeminar.length / this.itemsPerPage);
      this.totalPagesTraining = Math.ceil(this.displayedItemsTraining.length / this.itemsPerPage);
      this.totalPagesCourse = Math.ceil(this.displayedItemsCourse.length / this.itemsPerPage);
    },
    handleDate() {
      this.displayedItems = this.allInfo.filter(item => item.date_from == this.dateSeminar)

      this.displayedItemsSeminar = this.seminarList.filter(item => item.date_from == this.dateSeminar)
      this.displayedItemsTraining = this.trainingList.filter(item => item.date_from == this.dateSeminar)
      this.displayedItemsCourse = this.refresherCourseList.filter(item => item.date_from == this.dateSeminar)

      this.placeSeminar = ''
      this.typeSeminar = ''
      this.totalPages = Math.ceil(this.displayedItems.length / this.itemsPerPage);

      this.totalPagesSeminar = Math.ceil(this.displayedItemsSeminar.length / this.itemsPerPage);
      this.totalPagesTraining = Math.ceil(this.displayedItemsTraining.length / this.itemsPerPage);
      this.totalPagesCourse = Math.ceil(this.displayedItemsCourse.length / this.itemsPerPage);
    },
    handlePlace() {
      this.displayedItems = this.allInfo.filter(item => item.venue == this.placeSeminar)

      this.displayedItemsSeminar = this.seminarList.filter(item => item.venue == this.placeSeminar)
      this.displayedItemsTraining = this.trainingList.filter(item => item.venue == this.placeSeminar)
      this.displayedItemsCourse = this.refresherCourseList.filter(item => item.venue == this.placeSeminar)

      this.typeSeminar = ''
      this.dateSeminar = ''
      this.totalPages = Math.ceil(this.displayedItems.length / this.itemsPerPage);

      this.totalPagesSeminar = Math.ceil(this.displayedItemsSeminar.length / this.itemsPerPage);
      this.totalPagesTraining = Math.ceil(this.displayedItemsTraining.length / this.itemsPerPage);
      this.totalPagesCourse = Math.ceil(this.displayedItemsCourse.length / this.itemsPerPage);
    }
  },
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      currentPageSeminar: 1,
      currentPageTraining: 1,
      currentPageCourse: 1,
      fileList: [],
      modalCurrentRole: 'withReport',
      modalSignupForum: false,
      modalSignupForumReport: false,
      modalSignupForumNoReport: false,
      modalSignupForumPublication: false,
      modalSuccessfullyRegistered: false,
      modalSignupForumWidth: "688px",
      modalSuccessfullyRegisteredWidth: "400px",
      itemsPerPage: 3,
      dialogVisibleSeminars: false,
      modalWidthSeminars: "688px",
      input: "",
      textarea1: "",
      textarea2: "",
      seminarList: [],
      trainingList: [],
      refresherCourseList: [],
      allInfo: [],
      forumInfo: {},
      formType: {
        withReport : 'выступление с докладом и публикация',
        noReport : 'без доклада, без публикации',
        onlyPublication : 'только публикация',
      },
      presentationForm: {
        name : '',
        position: '',
        organization: '',
        theme : '',
        section: '',
        email: '',
        phone: '',
        payment_status: false
      },
      disabledBtn: false,
      phone: '',
      backUrl: window.API_TEST + '/storage/',
      typesList : [
        {id: 101, type: 'training', name: 'тренинг'},
        {id: 102,type: 'seminar', name: 'семинар'},
        {id: 103,type: 'refresher_course', name: 'курс повышения квалификации'},
      ],
      datesList: [],
      placesList: [],
      placeSeminar: '',
      dateSeminar: '',
      typeSeminar: '',
      displayedItems: [],
      displayedItemsSeminar: [],
      displayedItemsTraining: [],
      displayedItemsCourse: [],
      totalPagesSeminar: 1,
      totalPagesTraining: 1,
      totalPagesCourse: 1,
    }
  }
}
</script>
<style>

.forums-filters {
  display: flex;
  gap: 25px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.filter-period {
  position: relative;
}

.filter-period::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-period.svg");
}

.filter-period input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-period input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.filter-date input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-date input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.filter-date {
  position: relative;
}

.filter-date::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-date.svg");
}

.filter-place {
  position: relative;
}

.filter-place::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-place.svg");
}

.filter-place input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-place input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

@media screen and (max-width: 450px) {
  .el-select-dropdown {
    max-width: 92%;
  }
}

</style>
